import React, {CSSProperties, useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import s from './Clamp.scss';
import {TextButton, TextButtonPriority} from 'wix-ui-tpa/cssVars';

export enum ClampDataHook {
  ReadMoreButton = 'ClampDataHook.ReadMoreButton',
  ReadLessButton = 'ClampDataHook.ReadLessButton',
  HiddenTextCopy = 'ClampDataHook.HiddenTextCopy',
}

export type ClampProps = {
  enabled: boolean;
  text: string;
  className: string;
  readMoreLessLinkClassName: string;
  readMoreText: string;
  readLessText: string;
  maxLines: number;
  handleClampClick?: Function;
  dataHook?: string;
};

// istanbul ignore next: cant test with jsdom, should be tested by sled
export const Clamp: React.FunctionComponent<ClampProps> = ({
  children,
  text,
  className,
  readMoreLessLinkClassName,
  readMoreText,
  readLessText,
  enabled,
  dataHook,
  maxLines,
  handleClampClick,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const [isClamped, setIsClamped] = useState(true);
  const [shouldShowReadMoreLessLink, setShouldShowReadMoreLessLink] = useState(false);
  const textRef = useRef<HTMLSpanElement>(null);
  const ellipsisRef = useRef<HTMLSpanElement>(null);
  const [textToDisplay, setTextToDisplay] = useState<string>(text);
  const [state, forceUpdate] = useState(null);

  useEffect(() => {
    const onResize = () => forceUpdate({});
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  useEffect(() => {
    const textNodes = [...(textRef.current?.childNodes ?? [])];
    const offsetTops = new Set(textNodes.map((node: HTMLSpanElement) => node.offsetTop));
    if (offsetTops.size < maxLines) {
      setTextToDisplay(text);
      setShouldShowReadMoreLessLink(false);
      return;
    }

    const maxAllowedOffsetTop = [...offsetTops][maxLines - 1];
    while ((ellipsisRef.current?.lastChild as HTMLSpanElement).offsetTop > maxAllowedOffsetTop) {
      textRef.current?.removeChild(textRef.current?.lastChild);
    }

    if (textRef.current?.textContent === text) {
      setTextToDisplay(text);
      setShouldShowReadMoreLessLink(false);
      return;
    }

    setTextToDisplay(`${textRef.current?.textContent.trimEnd()}…`);
    setShouldShowReadMoreLessLink(true);
  }, [text, textRef, ellipsisRef, maxLines, state]);

  function splitTextBySpaceAndMaxLength() {
    // eslint-disable-next-line prefer-named-capture-group
    const textParts = text.split(/(\s+)/);
    for (let i = 0; i < textParts.length; i++) {
      if (textParts[i].length <= 10) {
        continue;
      }

      const splitByMaxNumberOfChars = textParts[i].match(/.{1,5}/g);
      textParts.splice(i, 1, ...splitByMaxNumberOfChars);
    }

    return textParts;
  }

  const readMoreLink = (
    <TextButton
      className={readMoreLessLinkClassName}
      priority={TextButtonPriority.link}
      onClick={() => {
        setIsClamped(!isClamped);
        handleClampClick?.(!isClamped);
      }}
      data-hook={isClamped ? ClampDataHook.ReadMoreButton : ClampDataHook.ReadLessButton}>
      {isClamped ? readMoreText : readLessText}
    </TextButton>
  );

  function renderHiddenTextCopy() {
    const textRefKey = textRef.current?.parentElement.clientWidth;
    const splitText = splitTextBySpaceAndMaxLength();

    return (
      <div className={classNames(s.textContainer, s.hidden, className)} data-hook={ClampDataHook.HiddenTextCopy}>
        <span ref={textRef} key={textRefKey}>
          {splitText.map((part, i) => (
            <span key={i}>{part}</span>
          ))}
        </span>
        <span ref={ellipsisRef}>
          <wbr />
          <span>{'… '}</span>
          {readMoreLink}
        </span>
      </div>
    );
  }

  const nonClampedHtml = (
    <div className={classNames(s.textContainer, className)} data-hook={dataHook}>
      {children}
    </div>
  );

  const style = {'--max-lines': maxLines} as CSSProperties;
  const clampedHtml = (
    <div className={s.clampContainer}>
      <div
        className={classNames(s.textContainer, className, {[s.clamp]: isClamped})}
        data-hook={dataHook}
        style={style}>
        {isClamped ? textToDisplay : text}
        {shouldShowReadMoreLessLink && (
          <span> {/*istanbul ignore next: cant test with jsdom, will be tested by sled*/ readMoreLink}</span>
        )}
      </div>
      {renderHiddenTextCopy()}
    </div>
  );

  return enabled ? clampedHtml : nonClampedHtml;
};
