import React from 'react';
import {ProductList} from '../../../gallery/components/ProductList/ProductList';
import {IGallerySantaProps, IPropsInjectedByViewerScript, MobileFiltersPanelState} from '../../../types/galleryTypes';
import s from '../../../gallery/components/GalleryApp/GalleryApp.scss';
import CategoriesClasses from './CategoriesApp.scss';
import classNames from 'classnames';
import {Omit} from '@wix/native-components-infra/dist/src/types/types';
import autobind from 'auto-bind-es5';
import {Announcer} from '@wix/wixstores-client-core/dist/es/src/a11y/announcer';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {withGlobals} from '../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../../gallery/galleryGlobalStrategy';
import {inlineStyleFix} from '../../../styles/inlineStyle';
import 'wicg-inert';
import {CategoriesBreadcrumbs} from '../CategoriesBreadcrumbs/CategoriesBreadcrumbs';
import {Hero} from '../Hero/Hero';
import {SideFilters} from '../../../common/components/SideFilters/SideFilters';
import {Sort} from '../../../common/components/Sort/Sort';
import {
  announceIfFilterResultChanged,
  shouldRenderSortInLayout,
  shouldShowGalleryAppInLayout,
} from '../../../gallery/components/GalleryApp/appUtils';
import {ProductsCounter} from '../ProductsCounter/ProductsCounter';
import {MobileFilters} from '../../../common/components/MobileFiltersAndSort/MobileFilters/MobileFilters';
import {EmptyGallery} from '../../../common/components/EmptyGallery/EmptyGallery';
import {AppliedFilters} from '../../../gallery/components/Filters/AppliedFilters/AppliedFilters';

export enum DataHook {
  Root = 'category-page-app-root',
  Content = 'category-page-app-content',
  Container = 'category-page-app-container',
  // eslint-disable-next-line @typescript-eslint/no-shadow
  SideFilters = 'category-page-aside-filters',
  MobileContainer = 'mobile-container',
  Breadcrumbs = 'category-page-breadcrumbs',
  BreadcrumbsComponent = 'category-breadcrumbs-component',
  ProductListContainer = 'product-list-container',
  CounterAndSortContainer = 'counter-sort-container',
  AppliedFiltersContainer = 'applied-filters-container',
}

export type IGalleryAppProps = Omit<IPropsInjectedByViewerScript & IGallerySantaProps, IGalleryGlobalProps['globals']> &
  IGalleryGlobalProps &
  IProvidedTranslationProps;

class CategoriesAppComp extends React.Component<IGalleryAppProps> {
  private a11yAnnouncer: Announcer;

  constructor(props) {
    super(props);
    this.state = {
      isSSR: props.isSSR,
    };
    autobind(this);
  }

  public componentDidMount() {
    this.scrollToProductIfReturnedFromProductPage();
    this.a11yAnnouncer = new Announcer('gallery-announcer');
    this.props.host.registerToComponentDidLayout(this.reportAppLoaded);
  }

  public componentDidUpdate(prevProps: IGalleryGlobalProps) {
    announceIfFilterResultChanged(prevProps, this.props, () =>
      this.a11yAnnouncer.announce(
        this.props.t('announceFiltersUpdate', {
          numberOfFoundProducts: this.props.globals.products && this.props.globals.products.length,
        })
      )
    );
  }

  public componentWillUnmount() {
    this.a11yAnnouncer.cleanup();
  }

  private scrollToProductIfReturnedFromProductPage() {
    const {
      globals: {
        scrollToProduct,
        clearScrollToProduct,
        experiments: {shouldScrollToProductPositionWhenReturningFromProductPageEnabled},
      },
    } = this.props;

    if (shouldScrollToProductPositionWhenReturningFromProductPageEnabled && scrollToProduct) {
      clearScrollToProduct();
      const productItem = document.querySelector(`[data-hook="${DataHook.Root}"] [data-slug="${scrollToProduct}"]`);
      if (productItem) {
        history.replaceState({scrollY: 0}, '');
        setTimeout(() => productItem.scrollIntoView({block: 'center'}), 0);
      }
    }
  }

  private reportAppLoaded() {
    if (this.props.globals.isInteractive && typeof this.props.onAppLoaded === 'function') {
      this.props.onAppLoaded();
    }
  }

  private emptyCategoryPage(classnames, responsive) {
    const {textsMap} = this.props.globals;
    return (
      <>
        <style dangerouslySetInnerHTML={{__html: inlineStyleFix}} />
        <div className={CategoriesClasses.layoutContainer}>
          <div data-hook={DataHook.Root} data-is-responsive={responsive} className={classnames.app}>
            <div data-hook={DataHook.Content} className={classnames.content}>
              <div data-hook={DataHook.Container} className={CategoriesClasses.container}>
                <EmptyGallery
                  localeMap={{
                    emptyCategoryPageEditorTitle: textsMap.emptyCategoryPageEditorTitle,
                    emptyCategoryPageEditorSubtitle: textsMap.emptyCategoryPageEditorSubtitle,
                  }}
                  isEditorCategoryPageMode={true}
                  hasFilters={false}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  public render() {
    const {styles, stylesParams, currentCategory, numberOfSelectedFilterTypes} = this.props.globals;
    const responsive = styles.get(stylesParams.responsive);
    const shouldShowHeroSection =
      styles.get(stylesParams.gallery_showCategoryHeaderSection) &&
      (styles.get(stylesParams.gallery_showCategoryHeaderImage) ||
        styles.get(stylesParams.gallery_showCategoryHeaderName) ||
        styles.get(stylesParams.gallery_showCategoryHeaderDescription));
    const shouldShowCategoriesProductsCounter = styles.get(stylesParams.gallery_showCategoriesProductsCounter);
    const shouldShowCategoriesBreadcrumbs = styles.get(stylesParams.gallery_showCategoriesBreadcrumbs);
    const {
      shouldShowMobile,
      mobileFiltersPanelState,
      experiments: {allowGalleryAppliedFiltersInViewer},
    } = this.props.globals;
    const isDesktop = !shouldShowMobile;
    const {hasSelectedFilters} = this.props.globals;

    if (!shouldShowGalleryAppInLayout(this.props.isLoaded, this.props.globals)) {
      return null;
    }

    const shouldRenderSideFilters =
      isDesktop && (styles.get(stylesParams.galleryShowFilters) || styles.get(stylesParams.gallery_showCategories));

    const shouldRenderDesktopSort = isDesktop && shouldRenderSortInLayout(this.props.globals);

    const shouldDisplayCountSortContainer =
      shouldShowCategoriesProductsCounter ||
      shouldRenderDesktopSort ||
      (shouldShowMobile && mobileFiltersPanelState !== MobileFiltersPanelState.NONE);

    const shouldShowAppliedFilters =
      allowGalleryAppliedFiltersInViewer &&
      styles.get(stylesParams.gallery_showAppliedFilters) &&
      numberOfSelectedFilterTypes > 0;

    const classnames = {
      app: classNames([s.galleryApp, CategoriesClasses.rightColumn], {
        deviceMobile: shouldShowMobile,
      }),
      content: classNames(s.content, s.fullWidth, {
        [s.contentResponsive]: responsive,
      }),
      containerSortAndCounter: classNames(CategoriesClasses.countAndFiltersContainer, {
        [CategoriesClasses.sortOnly]: !shouldShowCategoriesProductsCounter,
      }),
      appliedFiltersContainer: CategoriesClasses.appliedFiltersContainer,
      breadcrumbsContainer: classNames({
        [CategoriesClasses.breadcrumbsContainer]: !shouldShowMobile,
        [CategoriesClasses.breadcrumbsContainerMobile]: shouldShowMobile,
      }),
    };

    if (!currentCategory) {
      return this.emptyCategoryPage(classnames, responsive);
    }

    return (
      <>
        <style dangerouslySetInnerHTML={{__html: inlineStyleFix}} />
        <div className={CategoriesClasses.layoutContainer}>
          {shouldShowCategoriesBreadcrumbs && (
            <div className={classnames.breadcrumbsContainer}>
              <CategoriesBreadcrumbs />
            </div>
          )}
          <div className={CategoriesClasses.columnsContainer}>
            {shouldRenderSideFilters && (
              <div className={CategoriesClasses.leftColumn}>
                <SideFilters {...this.props.globals} shouldStretchVertically={false} />
              </div>
            )}
            <div data-hook={DataHook.Root} data-is-responsive={responsive} className={classnames.app}>
              <div data-hook={DataHook.Content} className={classnames.content}>
                <div data-hook={DataHook.Container} className={CategoriesClasses.container}>
                  {shouldShowHeroSection && (
                    <div className={CategoriesClasses.heroContainer}>
                      <Hero />
                    </div>
                  )}
                  <div data-hook={DataHook.ProductListContainer}>
                    {shouldShowAppliedFilters && (
                      <div
                        data-hook={DataHook.AppliedFiltersContainer}
                        className={classnames.appliedFiltersContainer}
                        role="group"
                        aria-label={this.props.t('appliedFiltersContainerSR')}>
                        <AppliedFilters />
                      </div>
                    )}
                    {shouldDisplayCountSortContainer && (
                      <div data-hook={DataHook.CounterAndSortContainer} className={classnames.containerSortAndCounter}>
                        {shouldShowCategoriesProductsCounter && <ProductsCounter />}
                        {shouldRenderDesktopSort && <Sort isFloatingView={true} />}
                        {shouldShowMobile && mobileFiltersPanelState !== MobileFiltersPanelState.NONE && (
                          <MobileFilters combineFiltersAndSort={true} isSplitView={true} />
                        )}
                      </div>
                    )}
                    <ProductList hasFilters={hasSelectedFilters} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export const CategoriesApp = withGlobals(withTranslations()(CategoriesAppComp));
